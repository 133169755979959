import React from "react"
import { StaticQuery, graphql } from "gatsby"
import Layout from "../components/layout/ca"
import SEO from "../components/seo"
import HeroImage from "../components/ui-sections/hero-image"
import TextSection from "../components/ui-sections/text-section"
import ImageGallery from "../components/ui-sections/image-gallery"

const EspaisPage = (props) => {
	const heroText = "Espais"
	const textSectionText = "Les nostres sales són espaioses i ambiantades en la nostra terra, l'Alt Penedès. Disposem de salons privats per events especials de grup, tant celebracions familiars o amb amics, com events d'empresa. La nostra bodega disposa d'una taula preciosa amb capacitat per 12 persones per gaudir, entre més de 300 referències de vi, d'un menú degustació que el Pere us prepararà a mida. També tenim una sala de digestius per després de dinar. No volem que tinguis cap presa. Volem que estiguis com a casa."
	return (
	  <Layout location={props.location}>
	    <SEO 
	    	title="Espais"
	    	lang="ca"
	    	description={textSectionText}
	    />
	    <HeroImage 
	    	titleText={heroText}
	    	image="espais" 
	    />
	    <TextSection textSectionText={textSectionText} />
	    <StaticQuery
	      query={graphql`
	        # Replace this comment with the GraphQL query above
	        {
	          allEspaisImagesCaYaml {
	            edges {
	              node {
	                title
	                image {
	                	id
	                  childImageSharp {
	                    fluid {
	                      aspectRatio
	                      originalImg
	                      # Use gatsby-image later for better user experience
	                      ...GatsbyImageSharpFluid
	        }}}}}}}
	      `}
	      render={data => (
	        <ImageGallery
	          itemsPerRow={[2, 5]} // This will be changed to `[2, 3]` later
	          images={data.allEspaisImagesCaYaml.edges.map(({ node }) => ({
	          	id: node.image.id,
	            ...node.image.childImageSharp.fluid,
	            caption: `${node.title}`,
	          }))}
	        />
	      )}
	    />
	  </Layout>
	)
}

export default EspaisPage
